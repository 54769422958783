import React, {useEffect, useState} from "react"
import { useFormik } from "formik"
import Axios from "axios"
import Modali, { useModali } from "../modal"
import emailImg from "../../images/video/email.png"
import { navigate } from "gatsby"
import {getMailChimpEndPoint} from "../../utils";
export default function PartnerSignForm(props) {
    const { state = {} } = props.location;

    const [finishModal, toggleFinishModal] = useModali({
        onHide: () => navigate("/"),
    })
    const [submitting, setSubmitting] = useState(false)
    const [requestInfo, setRequestInfo] = useState(false)
    const [requestModal, toggleRequestModal] = useModali()
    const [errorMsg, setErrorMsg] = useState("")
    const [mailChimpEndPoint, setMailChimpEndPoint] = useState("")
    // const [activeTab, toggleActiveTab] = useState(state ? state.isFindOut : false)
    const [activeTab, toggleActiveTab] = useState(true)

    useEffect(()=> {
        setMailChimpEndPoint(getMailChimpEndPoint());
    },[])

    const validate = (values) => {
        const errors = {}
        if (!values.firstName) {
            errors.firstName = "Required"
        }

        if (!values.lastName) {
            errors.lastName = "Required"
        }
        if (!values.password && !activeTab) {
            errors.password = "Required"
        }
        if (!values.company) {
            errors.company = "Required"
        }

        if (!values.phone) {
            errors.phone = "Required"
        } else if (values.phone.length < 10) {
            setErrorMsg("Phone should be 10 digit");
        } else {
            setErrorMsg("")
        }
        // if (!values.otherSoftware) {
        //   errors.otherSoftware = "Required"
        // }

        if (!values.customers) {
            errors.customers = "Required"
        }

        // if (!values.challenges) {
        //     errors.challenges = "Required"
        // }

        // if (!values.software && !values.otherSoftware) {
        //     errors.software = "Required"
        // }

        if (!values.email) {
            errors.email = "Required"
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address"
        }
        if (!values.staffMembers) {
          errors.staffMembers = "Required"
        }
        // if (!values.customersWithoutSoftware) {
        //   errors.customersWithoutSoftware = "Required"
        // }
        // if (!values.clientGovermentAgencies) {
        //   errors.clientGovermentAgencies = "Required"
        // }
        // if (!values.sendInvoice) {
        //   errors.sendInvoice = "Required"
        // }
        // if (!values.receiveInvoice) {
        //   errors.receiveInvoice = "Required"
        // }
        // if (!values.customerPrimarily) {
        //   errors.customerPrimarily = "Required"
        // }

        return errors
    }

    const formik = useFormik({
        initialValues: {
            checkbox1: true,
            checkbox2: true,
        },
        validate,
        onSubmit: () => {
            setErrorMsg("");
            setSubmitting(true)
            if(activeTab){
                Axios.post(mailChimpEndPoint, {
                    email: formik.values.email,
                    audienceId: "a8644ff9cb",
                    tags: ["A&B20"],
                    mergeFields: {
                        FNAME: formik.values.firstName,
                        LNAME: formik.values.lastName,
                        EMAIL: formik.values.email,
                        PHONE: formik.values.phone,
                        MMERGE3: formik.values.customers,
                        // MMERGE7: formik.values.challenges,
                        MMERGE5: formik.values.software,
                        // MMERGE6: formik.values.otherSoftware,
                        COMPNAME: formik.values.company,
                        MMERGE10: formik.values.company,
                        MMERGE16: formik.values.staffMembers,
                        // MMERGE17: formik.values.customersWithoutSoftware,
                        // MMERGE18: formik.values.clientGovermentAgencies,
                        // MMERGE19: formik.values.sendInvoice,
                        // MMERGE20: formik.values.receiveInvoice,
                        // MMERGE21: formik.values.customerPrimarily,
                        REQDEMO: formik.values.checkbox1 ? "yes" : "no",
                        ACCEPTTERM: formik.values.checkbox2 ? "yes" : "no",
                    },
                })
                    .then(function(response) {
                        console.log(response)
                        setSubmitting(false)
                        if (response) {
                            if (response.data.success) {
                                // toggleFinishModal()
                                setErrorMsg("")
                                toggleRequestModal();
                            } else {
                                if (response.data.message) {
                                    console.log(response.data.message)
                                    setErrorMsg(response.data.message.split(". Use")[0])
                                }
                            }
                        }
                    })
                    .catch(function(error) {
                        setErrorMsg("Something went wrong, Please Try Again!");
                        setSubmitting(false)
                    })
            } else {
                Axios.post(mailChimpEndPoint, {
                    email: formik.values.email,
                    audienceId: "a8644ff9cb",
                    tags: ["A&B20"],
                    mergeFields: {
                        FNAME: formik.values.firstName,
                        LNAME: formik.values.lastName,
                        EMAIL: formik.values.email,
                        PHONE: formik.values.phone,
                        MMERGE3: formik.values.customers,
                        // MMERGE7: formik.values.challenges,
                        MMERGE5: formik.values.software,
                        // MMERGE6: formik.values.otherSoftware,
                        COMPNAME: formik.values.company,
                        MMERGE10: formik.values.company,
                        MMERGE16: formik.values.staffMembers,
                        // MMERGE17: formik.values.customersWithoutSoftware,
                        // MMERGE18: formik.values.clientGovermentAgencies,
                        // MMERGE19: formik.values.sendInvoice,
                        // MMERGE20: formik.values.receiveInvoice,
                        // MMERGE21: formik.values.customerPrimarily,
                        REQDEMO: formik.values.checkbox1 ? "yes" : "no",
                        ACCEPTTERM: formik.values.checkbox2 ? "yes" : "no",
                    },
                })
                    .then(function(response) {
                        console.log(response)
                        if (response) {
                            if (response.data.success) {
                                Axios.post(`${process.env.GATSBY_APP_URL}` + "/api/v1/user/register", {
                                    firstname: formik.values.firstName,
                                    lastname: formik.values.lastName,
                                    username: formik.values.email,
                                    password: formik.values.password,
                                    contactNumber: formik.values.phone,
                                    isTrial: true,
                                    type: "partner"
                                })
                                    .then(function(registerResponse) {
                                        setSubmitting(false)
                                        if (registerResponse) {
                                            // console.log(response)`
                                            if (registerResponse.data.success) {
                                                setErrorMsg("")
                                                window.location.href = registerResponse.data.redirectUrl
                                            } else {
                                                if(registerResponse.data.msg === "Failed to register user") {
                                                    setErrorMsg("Email is already registered");
                                                } else {
                                                    setErrorMsg(registerResponse.data.msg);
                                                }
                                            }

                                        }
                                    })
                                    .catch(function(error) {
                                        setSubmitting(false)
                                        setErrorMsg("Something went wrong, Please Try Again!");
                                    })
                            } else {
                                if (response.data.message) {
                                    console.log(response.data.message)
                                    setErrorMsg(response.data.message.split(". Use")[0])
                                    setSubmitting(false)
                                }
                            }
                        } else {
                            setSubmitting(false)
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                        setSubmitting(false)
                        if(error.response && error.response.data){
                            setErrorMsg(error.response.data);
                        } else {
                            setErrorMsg("Something went wrong, Please Try Again!");
                        }
                    })
            }
        }
    })

    return (
        <div className="signup-form mx-auto">
            <a name="download_form" id="download_form"></a>
            {errorMsg && (
                <div className=" text-red-600 mb-4 text-left">{errorMsg}</div>
            )}
            {/*<div className="top flex flex-col sm:flex-row mb-5 cursor-pointer">*/}
            {/*    <div className={`box1 ${activeTab && "active"} w-50 mx-0 text-neutral-3`} onClick={() => toggleActiveTab(!activeTab)}>*/}
            {/*        Sign Up*/}
            {/*    </div>*/}
            {/*    <div className="line" />*/}
            {/*    <div className={`box2 ${!activeTab && "active"} w-50 mx-0 text-neutral-3`} onClick={() => toggleActiveTab(!activeTab)}>*/}
            {/*        Find Out More*/}
            {/*    </div>*/}
            {/*</div>*/}
            <form action="" className="" onSubmit={formik.handleSubmit}>
                <div className="">
                    <input
                        className={`small px-5 py-1 lgdark  border placeholder-gray-28
            ${
                            formik.errors.firstName ? "border-red-600" : "border-gray-27"
                        }  rounded`}
                        name="firstName"
                        type="text"
                        required=""
                        placeholder="First name"
                        aria-label="First name"
                        onChange={formik.handleChange}
                        value={formik.values.firstName || ""}
                    />

                    <input
                        className={`small ml-2 lg:ml-4 px-5 py-1 lgdark border placeholder-gray-28
             ${
                            formik.errors.lastName ? "border-red-600" : "border-gray-27"
                        }   rounded`}
                        name="lastName"
                        type="text"
                        required=""
                        placeholder="Last name"
                        aria-label="Last name"
                        onChange={formik.handleChange}
                        value={formik.values.lastName || ""}
                    />
                </div>
                <div className="mt-3">
                    <input
                        className={`small px-5 py-1 lgdark placeholder-gray-28  border   ${
                            formik.errors.email ? "border-red-600" : "border-gray-27"
                        }   rounded`}
                        name="email"
                        type="text"
                        required=""
                        placeholder="Email "
                        aria-label="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email || ""}
                    />
                    <input
                        className={`small ml-2 lg:ml-4 px-5 py-1 lgdark placeholder-gray-28  border   ${
                            formik.errors.phone ? "border-red-600" : "border-gray-27"
                        }  rounded`}
                        name="phone"
                        type="text"
                        required=""
                        maxLength={10}
                        placeholder="Phone"
                        aria-label="Phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone || ""}
                    />
                </div>
                {!activeTab && <div className="mt-3 flex items-center">
                    <input
                        className={`big px-5 py-1 lgdark placeholder-gray-28   border ${
                            !requestInfo && formik.errors.password ? "border-red-600" : "border-gray-27"
                        }  rounded`}
                        name="password"
                        type="password"
                        required=""
                        placeholder="Password"
                        aria-label="Company"
                        onChange={formik.handleChange}
                        value={formik.values.password || ""}
                    />
                </div>}
                <div className="mt-3">
                    <input
                        className={`big px-5 py-1 lgdark placeholder-gray-28   border ${
                            formik.errors.company ? "border-red-600" : "border-gray-27"
                        }  rounded`}
                        name="company"
                        type="text"
                        required=""
                        placeholder="Business Name"
                        aria-label="Company"
                        onChange={formik.handleChange}
                        value={formik.values.company || ""}
                    />
                </div>
                <div className="mt-3">
                    <label className="block text-left lable" htmlFor="numbers">
                        Number of clients you work with
                    </label>
                    <select
                        name="customers"
                        value={formik.values.customers}
                        onChange={formik.handleChange}
                        className={`big big-select px-5 py-1 text-gray-28 placeholder-gray-28  border ${
                            formik.errors.customers ? "border-red-600" : "border-gray-27"
                        }  rounded`}
                    >
                        <option value=""> Select</option>
                        <option value="1+">1+</option>
                        <option value="5+">5+</option>
                        <option value="10+">10+</option>
                        <option value="20+">20+</option>
                        <option value="50+">50+</option>
                    </select>
                </div>

          {/*      <div className="mt-3">*/}
          {/*          <label className="block text-left lable  " htmlFor="numbers">*/}
          {/*              What are your business challenges?*/}
          {/*          </label>*/}
          {/*          <select*/}
          {/*              name="challenges"*/}
          {/*              value={formik.values.challenges}*/}
          {/*              onChange={formik.handleChange}*/}
          {/*              className={`big big-select px-5 py-1*/}
          {/*text-gray-28 placeholder-gray-28   border ${*/}
          {/*                  formik.errors.challenges ? "border-red-600" : "border-gray-27"*/}
          {/*              }   rounded`}*/}
          {/*          >*/}
          {/*              <option value="">Select</option>*/}
          {/*              <option value="Chasing payments">Chasing payments</option>*/}
          {/*              <option value="Re-sending incorrect invoices">Re-sending incorrect invoices</option>*/}
          {/*              <option value="Sourcing the right e-invoicing solution">Sourcing the right e-invoicing solution</option>*/}
          {/*              <option value="Unsure if my existing accounting software provider can e-invoice">Unsure if my existing accounting software provider can e-invoice</option>*/}
          {/*              <option value="Unsure what the benefits of e-invoicing is">Unsure what the benefits of e-invoicing is</option>*/}
          {/*              <option value="Unsure how to implement e-invoicing for my Practice">Unsure how to implement e-invoicing for my Practice</option>*/}
          {/*          </select>*/}
          {/*      </div>*/}
                <div className="mt-3">
                    <label className="block text-left lable" htmlFor="numbers">
                        What accounting software are you using?
                    </label>
                    <select
                        className={`big big-select py-0 text-gray-28
                border ${
                            formik.errors.software ? "border-red-600" : "border-gray-27"
                        }  rounded`}
                        name="software"
                        value={formik.values.software}
                        onChange={formik.handleChange}
                    >
                        <optgroup>
                            <option value="">Select</option>
                            <option value="XERO">XERO</option>
                            <option value="MYOB">MYOB</option>
                            <option value="QuickBooks">QuickBooks</option>
                            <option value="SAGE">SAGE</option>
                            <option value="APS">APS</option>
                            <option value="Other">Other</option>
                        </optgroup>
                    </select>
                </div>
                {/*<div className="mt-3">*/}
                {/*    <label className="block text-left lable" htmlFor="numbers">*/}
                {/*        Other accounting software*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        className={`big px-5 py-1 lgdark  border border-gray-27  roundedr ${*/}
                {/*            formik.errors.otherSoftware ? "border-gray-27" : "border-gray-27"*/}
                {/*        } rounded `}*/}
                {/*        name="otherSoftware"*/}
                {/*        type="text"*/}
                {/*        required=""*/}
                {/*        placeholder=""*/}
                {/*        aria-label="Other accounting software"*/}
                {/*        onChange={formik.handleChange}*/}
                {/*        value={formik.values.otherSoftware || ""}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="mt-3">
                    <label className="block text-left lable" htmlFor="numbers">
                        How many staff members do you have?
                    </label>
                    <select
                      name="staffMembers"
                      value={formik.values.staffMembers}
                      onChange={formik.handleChange}
                      className={`big big-select px-5 py-1 text-gray-28 placeholder-gray-28  border ${
                        formik.errors.staffMembers ? "border-red-600" : "border-gray-27"
                      }  rounded`}
                    >
                        <option value=""> Select</option>
                        <option value="0-5">0-5</option>
                        <option value="6-10">6-10</option>
                        <option value="11-20">11-20</option>
                        <option value="21-30">21-30</option>
                        <option value="30+">30+</option>
                    </select>
                </div>
                {/*<div className="mt-3">*/}
                {/*    <label className="block text-left lable" htmlFor="numbers">*/}
                {/*        How many of your clients don't use existing accounting software?*/}
                {/*    </label>*/}
                {/*    <select*/}
                {/*      name="customersWithoutSoftware"*/}
                {/*      value={formik.values.customersWithoutSoftware}*/}
                {/*      onChange={formik.handleChange}*/}
                {/*      className={`big big-select px-5 py-1 text-gray-28 placeholder-gray-28  border ${*/}
                {/*        formik.errors.customersWithoutSoftware ? "border-red-600" : "border-gray-27"*/}
                {/*      }  rounded`}*/}
                {/*    >*/}
                {/*        <option value=""> Select</option>*/}
                {/*        <option value="5+">5+</option>*/}
                {/*        <option value="10+">10+</option>*/}
                {/*        <option value="20+">20+</option>*/}
                {/*        <option value="50+">50+</option>*/}
                {/*        <option value="100+">100+</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
              {/*  <div className="mt-3">*/}
              {/*  <label className="block text-left lable" htmlFor="numbers">*/}
              {/*      How many of your clients currently work with Government Agencies?*/}
              {/*  </label>*/}
              {/*  <select*/}
              {/*    name="clientGovermentAgencies"*/}
              {/*    value={formik.values.clientGovermentAgencies}*/}
              {/*    onChange={formik.handleChange}*/}
              {/*    className={`big big-select px-5 py-1 text-gray-28 placeholder-gray-28  border ${*/}
              {/*      formik.errors.clientGovermentAgencies ? "border-red-600" : "border-gray-27"*/}
              {/*    }  rounded`}*/}
              {/*  >*/}
              {/*    <option value=""> Select</option>*/}
              {/*    <option value="5+">5+</option>*/}
              {/*    <option value="10+">10+</option>*/}
              {/*    <option value="20+">20+</option>*/}
              {/*    <option value="50+">50+</option>*/}
              {/*    <option value="100+">100+</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
              {/*  <div className="mt-3">*/}
              {/*      <label className="block text-left lable" htmlFor="numbers">*/}
              {/*          How many invoices on average does your Practice send a month?*/}
              {/*      </label>*/}
              {/*      <select*/}
              {/*        name="sendInvoice"*/}
              {/*        value={formik.values.sendInvoice}*/}
              {/*        onChange={formik.handleChange}*/}
              {/*        className={`big big-select px-5 py-1 text-gray-28 placeholder-gray-28  border ${*/}
              {/*          formik.errors.sendInvoice ? "border-red-600" : "border-gray-27"*/}
              {/*        }  rounded`}*/}
              {/*      >*/}
              {/*          <option value=""> Select</option>*/}
              {/*          <option value="50+">50+</option>*/}
              {/*          <option value="100+">100+</option>*/}
              {/*          <option value="500+">500+</option>*/}
              {/*          <option value="1,000+">1K+</option>*/}
              {/*          <option value="5,000+">5K+</option>*/}
              {/*          <option value="10,000+">10K+</option>*/}
              {/*      </select>*/}
              {/*  </div>*/}
              {/*  <div className="mt-3">*/}
              {/*      <label className="block text-left lable" htmlFor="numbers">*/}
              {/*          How many invoices as a practice do you receive a month?*/}
              {/*      </label>*/}
              {/*      <select*/}
              {/*        name="receiveInvoice"*/}
              {/*        value={formik.values.receiveInvoice}*/}
              {/*        onChange={formik.handleChange}*/}
              {/*        className={`big big-select px-5 py-1 text-gray-28 placeholder-gray-28  border ${*/}
              {/*          formik.errors.receiveInvoice ? "border-red-600" : "border-gray-27"*/}
              {/*        }  rounded`}*/}
              {/*      >*/}
              {/*          <option value=""> Select</option>*/}
              {/*          <option value="50+">50+</option>*/}
              {/*          <option value="100+">100+</option>*/}
              {/*          <option value="500+">500+</option>*/}
              {/*          <option value="1,000+">1K+</option>*/}
              {/*          <option value="5,000+">5K+</option>*/}
              {/*          <option value="10,000+">10K+</option>*/}
              {/*      </select>*/}
              {/*  </div>*/}
              {/*  <div className="mt-3">*/}
              {/*    <label className="block text-left lable" htmlFor="numbers">*/}
              {/*      What industry are your clients primarily in?*/}
              {/*    </label>*/}
              {/*    <select*/}
              {/*      name="customerPrimarily"*/}
              {/*      value={formik.values.customerPrimarily}*/}
              {/*      onChange={formik.handleChange}*/}
              {/*      className={`big big-select px-5 py-1 text-gray-28 placeholder-gray-28  border ${*/}
              {/*        formik.errors.customerPrimarily ? "border-red-600" : "border-gray-27"*/}
              {/*      }  rounded`}*/}
              {/*    >*/}
              {/*      <option value=""> Select</option>*/}
              {/*      <option value="Automotive">Automotive</option>*/}
              {/*      <option value="Business Services">Business Services</option>*/}
              {/*      <option value="Communications">Communications</option>*/}
              {/*      <option value="Construction">Construction</option>*/}
              {/*      <option value="Energy">Energy</option>*/}
              {/*      <option value="Financial Services">Financial Services</option>*/}
              {/*      <option value="Government">Government</option>*/}
              {/*      <option value="Healthcare">Healthcare</option>*/}
              {/*      <option value="Hospitality">Hospitality</option>*/}
              {/*      <option value="Manufacturing">Manufacturing</option>*/}
              {/*      <option value="Media">Media</option>*/}
              {/*      <option value="Mining">Mining</option>*/}
              {/*      <option value="Non Profit/Education">Non Profit/Education</option>*/}
              {/*      <option value="Property Development">Property Development</option>*/}
              {/*      <option value="Realestate">Realestate</option>*/}
              {/*      <option value="Retail">Retail</option>*/}
              {/*      <option value="Technology Provider">Technology Provider</option>*/}
              {/*      <option value="Travel &amp; Leisure">Travel & Leisure</option>*/}
              {/*      <option value="Utilities">Utilities</option>*/}
              {/*      <option value="Other">Other</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
                <div className="w-full flex items-center justify-start mt-6 mb-3 ">
                    <input
                        type="checkbox"
                        name="checkbox1"
                        checked={formik.values.checkbox1 || false}
                        onChange={formik.handleChange}
                        className="bg-white border border-gray-27 "
                    />
                    <span className="text-11 text-gray-700   text-left ml-2">
            Request a demo of LUCA Plus
          </span>
                </div>
                <div
                    className="w-full flex items-start sm:items-center justify-start
        "
                >
                    <input
                        type="checkbox"
                        name="checkbox2"
                        checked={formik.values.checkbox2 || false}
                        onChange={formik.handleChange}
                        className="bg-white  border border-gray-27"
                    />
                    <span className="text-11 text-left text-gray-700 ml-2 -mt-1 sm:-mt-0">
            I accept LUCA Plus
            <a
                href="https://docs.lucaplus.com/terms-of-service"
                className="underline mx-1"
            >
              Terms of Services
            </a>
            and
            <a
                href="https://docs.lucaplus.com/security-compliance-privacy"
                className="underline mx-1"
            >
              Privacy Policy
            </a>
          </span>
                </div>
                <button
                    type="submit"
                    className={`w-full text-white  focus:outline-none  submit ${
                        submitting ? "bg-gray-6" : "bg-mind"
                    } mt-6`}
                    disabled={submitting}
                >
                    {!activeTab ? " Sign Up" : "Submit"}
                </button>
            </form>
            <Modali.Modal animated={true} {...requestModal} className=" signup-modal">
                <div className="pt-8 pop-up-video mx-auto ">
                    <div className="pop-up-video-inner  text-neutral-3 mx-auto ">
                        <img src={emailImg} alt="" className="inbox-image mx-auto" />
                        <div className="leading-22 mt-8 content">
                            Thank you for enquiring about LUCA Plus' Partner Program.
                            <div className="mt-2">
                                Please check your inbox with next steps.
                            </div>
                        </div>
                        <div className="mt-6 font-medium  text-lg">Team LUCA Plus</div>
                    </div>
                </div>
            </Modali.Modal>
        </div>
    )
}

