import React from "react"

export default function Hero(props) {
  const { state = {} } = props.location;

  return (
    <div className="hero flex justify-center h-full">
        {state && state.partner ? <div className="title mx-auto">
            You’re one step closer to joining 40+ Partners who have already joined our Partner Success Program  A partner trying to sign up and clicked on Find Out More but it brought him to the A&B page
          </div>
        : state.blbpartner ?
            <div className="title mx-auto">
              Join the <span className="text-mind">30+</span> business loan and asset finance business partners that have already signed up for our program - don’t be left out!
            </div>
            :
            <div className="title mx-auto">
          Join the <span className="text-mint2">40+</span> Accountants/Bookkeepers who have already joined our
          Partner Program - Don’t get left behind
      </div>}
    </div>
  )
}
