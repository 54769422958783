import React from "react"
import SignupForm from "./partnerForm"

export default function Main(props) {
  const { state = {} } = props.location;
  return (
     state && state.partner ? <div className="main">
      <p className="desc">

        Our Partner Success Program will provide you access to a dedicated account manager, reward incentives and free marketing
        resources to help you and your clients achieve business goals together.<br />

        Complete your details below to start enjoying the benefits our Partner Program offers by accessing our Partner Success
        Program Plan for FREE:

      </p>

      <SignupForm {...props} />
    </div>
    : <div className="main">
      <p className="desc">
        We invite Australian based accountants and bookkeepers to join the LUCA
        Plus Partner Program today.
        <br />
        Our Partner Program gives you access to a dedicated account manager,
        reward incentives and free marketing resources to help you and your
        clients achieve business goals together.
        <br />
        It’s FREE to join – Ask about our Partner Program today!
      </p>
     <SignupForm {...props} />
    </div>
  )
}
