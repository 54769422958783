import React, { useState } from "react"
import logoImage from "../../images/new-landing/logo.svg"
import { meetingUrl } from "../../constants/url"
import classNames from "classnames"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"

export default function Top({partner = false, toggleVideoModal, navItemClick}) {
  const [toggle, setToggle] = useState(false)
  const toggleCls = classNames('navbar-toggler',
    { 'collapsed': !toggle }
  )
  const mobileMenuWrapperCls = classNames(
    { 'show': toggle }
  )

  const handleToggleMenu = (e) => {
    setToggle(!toggle)
  }
  return (
      <header className="site-header site-header--nofix">
        <div className="container">
          <div className="header-inner">
            <nav className="site-nav">
              <div className="logo sm:ml-10 ml-8 sm:mb-0 mb-2 flex items-center sm:flex-row flex-col">
                <Link to="/" ><img src={Logo} alt="luca logo" className="mr-3" /></Link>
                <div className="text sm:mt-0 mt-1 whitespace-no-wrap sm:ml-3 ml-0 text-14">
                  Simpler and Faster Business
                </div>
              </div>

              <div id="site-menu-wrapper">
                <ul className="site-menu">
                  <li onClick={() => navItemClick("Watch how it works")}>
                    <button className="play-btn focus:outline-none my-0 py-2" onClick={toggleVideoModal}>
                      Watch how it works
                      <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z" fill="white" />
                      </svg>
                    </button>
                  </li>
                  <li onClick={() => navItemClick("FAQ")}>
                    <a  href="https://go.luca.plus/partner-program-faq" target="__blank" className="hover:text-gray-26 ml-5 cursor-pointer">
                      FAQ
                    </a>
                  </li>
                  <li onClick={() => navItemClick("Contact Us")}>
                    <a  href="https://meetings.hubspot.com/leo-huang" target="__blank" className="hover:text-gray-26 ml-5 cursor-pointer">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className={toggleCls} onClick={handleToggleMenu}>
              <span className="menu_toggle" >
                <span className="hamburger">
                  <span/>
                  <span/>
                  <span/>
                </span>
                <span className="hamburger-cross">
                  <span/>
                  <span/>
                </span>
              </span>
              </div>
            </nav>
          </div>
        </div>
        <div id="mobile-menu-wrapper" className={mobileMenuWrapperCls}>
          <ul className="mobile-menu">
            <li>
              <a target="__blank" className="mx-10" onClick={() => navItemClick("Watch how it works")}>
                <button className="play-btn focus:outline-none my-0 py-2" onClick={toggleVideoModal}>
                  Watch how it works
                  <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z" fill="white" />
                  </svg>
                </button>
              </a>
            </li>
            <li onClick={() => navItemClick("FAQ")}>
              <a  href="https://go.luca.plus/partner-program-faq" target="__blank" className="hover:text-gray-26 mx-10">
                FAQ
              </a>
            </li>
            <li onClick={() => navItemClick("Contact Us")}>
              <a  href="https://meetings.hubspot.com/leo-huang" target="__blank" className="hover:text-gray-26">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </header>
  )
}
