import React, { useLayoutEffect } from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"

import Footer from "../components/footer"
import "../scss/style.scss"

import Top from "../components/signup/top"
import Main from "../components/signup/main"
import Hero from "../components/signup/hero"
import seoImage from "../images/partner/bg_mobile.png"

export default function Signup(props) {
    const navItemClick = (navItem) => {
        ReactGA.event({
            category: 'NAV_ITEM',
            action: `Clicked ${navItem} Nav Item of Sign up Page`
        });
    }
  return (
    <div className="h-full font-robo signup">
      <SEO title="Join the LUCA+ E-invoicing Partner Program | LUCA+ Australia (XERO, MYOB, QuickBooks)" einvoice={true} description="Join LUCA+ e-invoicing Partner Program and be rewarded as your clients grow." image={seoImage} 
      keywords="e-invoicing partner program" />
      <Top navItemClick={navItemClick} />
      <Hero {...props} />
      <Main {...props} />

      <Footer simple={true} />
    </div>
  )
}
